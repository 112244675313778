export const decimalCount = (num) => {
  const numStr = String(num);
  if (numStr.includes('.')) {
    return numStr.split('.')[1].length;
  }
  return 0;
};

export const parseNumber = (value) => {
  const parsedValue = value
    .replace(/[,]/g, '.')
    .replace(/[^0-9.-]/g, '')
    .replace(/^(-)|-+/g, '$1')
    .replace(/^([^.]*\.)|\.+/g, '$1');
  if (decimalCount(parsedValue) > 2) {
    return Number(parsedValue.slice(0, parsedValue.length - 1));
  }
  return parsedValue;
};

export const parseNumber3digits = (value) => {
  const parsedValue = value
    .replace(/[,]/g, '.')
    .replace(/[^0-9.-]/g, '')
    .replace(/^(-)|-+/g, '$1')
    .replace(/^([^.]*\.)|\.+/g, '$1');
  if (decimalCount(parsedValue) > 3) {
    return Number(parsedValue.slice(0, parsedValue.length - 1));
  }
  return parsedValue;
};

export const parseToNumberMinZero = (value) => {
  if (Number(value) < 0) {
    return 0;
  }
  return value;
};
