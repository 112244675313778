import { Box, Typography } from '@mui/material';

import PlusIcon from 'public/svg/plus.svg';
import FilterIcon from 'public/svg/filter.svg';
import CustomButton from 'src/components/Button';
import useFilterOpen from 'src/hooks/useFilterOpen';
import Balance from 'src/components/Balance';
import { StyledToolbar } from 'src/components/Layout';
import { CustomToolbarProps } from 'src/interfaces';

const CustomToolbar = ({
  createButtonTitle = ' ',
  title = ' ',
  onCreateButtonClick,
  isFilterClose = false,
  hideButton = false,
  balance,
  isMobile,
  sx,
}: CustomToolbarProps) => {
  const { toggleFilterOpen } = useFilterOpen();
  return (
    <StyledToolbar>
      <div style={{ display: 'flex' }}>
        {title && (
        <Typography
          variant="h5"
          component="div"
          sx={{
            color: 'black',
            fontWeight: 600,
            mr: 2,
          }}
        >
          {title}
        </Typography>
        )}
        {createButtonTitle && (
        <CustomButton
          isMobile={isMobile}
          title={createButtonTitle}
          onClick={() => onCreateButtonClick(true)}
          startIcon={!isMobile && <PlusIcon />}
          sx={{
            ...(hideButton && {
              visibility: 'hidden',
            }),
            ...(sx && {
              ...sx,
            }),
          }}
        />
        )}
      </div>
      {isMobile && balance && (
        <Balance isMobile={isMobile} price={balance} />
      )}
      {!isFilterClose && (
        <Box>
          {isMobile ? (
            <CustomButton
              title={<FilterIcon />}
              variant="outlined"
              onClick={toggleFilterOpen}
              light
              sx={{ border: 'none' }}
            />
          ) : (
            <CustomButton
              title="Фильтр"
              startIcon={<FilterIcon />}
              variant="outlined"
              onClick={toggleFilterOpen}
              light
            />
          )}
        </Box>
      )}
    </StyledToolbar>
  );
};

export default CustomToolbar;
