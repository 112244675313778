import { Typography, Box } from '@mui/material';

interface BalanceProps {
  price: number;
  isMobile?: boolean,
}

const Balance = ({ price, isMobile }: BalanceProps) => (
  <Typography
    variant="h4"
    sx={{
      fontSize: '24px',
      fontWeight: '600',
      color: '#676767',
      mr: '24px',
      ...(isMobile && {
        fontSize: '16px',
        marginRight: '0px',
        textAlign: 'end',
      }),
    }}
  >
    Баланс: <Box component="span" sx={{ fontSize: isMobile ? '16px' : '24px', fontWeight: '600', color: 'black' }}>{price}₽</Box>
  </Typography>
);

export default Balance;
