import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'src/store/main/slice';

const useFilterOpen = () => {
  const dispatch = useDispatch();

  const isFilterOpen = useSelector((store: any) => store.main.isFilterOpen);

  const toggleFilterOpen = () => dispatch(actions.toggleFilterOpen());

  return { isFilterOpen, toggleFilterOpen };
};

export default useFilterOpen;
