import api from './index';

export const getContractors = async (params) => {
  const data = await api.get('deals/contractors/', { qs: { ...params } });
  return data;
};

export const getContractorPlatform = async (params) => {
  const data = await api.get('deals/platforms/', { qs: { ...params } });
  return data;
};

export const getUsers = async (params) => {
  const data = await api.get('users/users/', { qs: { ...params } });
  return data;
};

export const getNomenclatures = async (params) => {
  const data = await api.get('deals/nomenclatures/', { qs: { ...params } });
  return data;
};

export const getTransportCompanies = async (params) => {
  const data = await api.get('deals/transport_companies/', { qs: { ...params } });
  return data;
};
export const getCities = async (params) => {
  const data = await api.get('map/cities/', { qs: { ...params } });
  return data;
};

export const getBids = async (params) => {
  const data = await api.get('deals/bids/', { qs: { ...params } });
  return data;
};

export const getEntities = async (params) => {
  const data = await api.get('deals/entities/', { qs: { ...params } });
  return data;
};

export const getPayment = async (params) => {
  const data = await api.get('billing/payments/', { qs: { ...params } });
  return data;
};

export const getDeal = async (params) => {
  const data = await api.get('deals/deals/', { qs: { ...params } });
  return data;
};
