import React, { useRef } from 'react';
import { InputComponentProps } from 'src/interfaces';
import { StyledInput } from 'src/components/Layout';

const Input: React.FC<any> = ({
  label,
  placeholder,
  input,
  meta,
  variant,
  required,
  sx,
  inputProps,
  type,
  params,
  helperText,
  maxRows,
  ...rest
}: InputComponentProps) => {
  const inputRef = useRef(null);

  const buildErrorMessage = (error) => {
    if (typeof error === 'object') {
      const keys = Object.keys(error);
      const buildedError = keys.reduce((acc, key) => {
        acc += error[key];
        return acc;
      }, '');
      return buildedError;
    }
    return error;
  };
  return (
    <StyledInput
      placeholder={placeholder}
      label={label}
      variant={variant}
      required={required}
      ref={inputRef}
      inputProps={inputProps}
      sx={sx}
      error={meta.touched && !!(meta.error)}
      helperText={meta.touched && meta.error ? buildErrorMessage(meta.error) : helperText}
      type={type}
      maxRows={maxRows}
      {...params}
      {...input}
      {...rest}
    />

  );
};

export default Input;
